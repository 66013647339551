import React, { useState } from 'react'
import {
  Avatar,
  abbreviate,
  Icon,
  Relative,
  Circle,
  Flex,
  Token,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { getAvatarUrl } from '@src/utils/employees'
import { UserAvatarUpload } from '@src/features/UserAvatarUpload/UserAvatarUpload'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { formatRequestDate } from '@src/constants/columns/timeOff'
import { EmployeeTimeOffRequestInterface } from '@src/interfaces/timeOff'

interface FormTitleAvatarProps {
  data: EmployeeInterface
  refreshData: () => void
  timeOff?: EmployeeTimeOffRequestInterface
}

export const FormTitleAvatar = ({ data, refreshData, timeOff }: FormTitleAvatarProps) => {
  const user = useSelector(selectUser)
  const tooltip = useTooltip()
  const [avatarUploadOpen, setAvatarUploadOpen] = useState(false)
  const [isMouseOver, setMouseOver] = useState(false)

  const timeOffBadge = (
    <Avatar.Badge bg={Token.color.warning} useIcon="Resort">
      {timeOff?.to_date_time && (
        <Tooltip {...tooltip.getTargetProps()}>
          <Flex style={{ whiteSpace: 'break-spaces' }}>
            OOO until {formatRequestDate(timeOff.to_date_time, timeOff.unit.id)}
          </Flex>
        </Tooltip>
      )}
    </Avatar.Badge>
  )

  if (user.id !== data.id) {
    return (
      <Avatar
        size={56}
        image={getAvatarUrl(data.avatar, 'md')}
        label={abbreviate(data.full_name)}
        {...tooltip.getAnchorProps()}
        {...(timeOff && {
          progress: 1,
          progressColor: Token.color.warning,
        })}
      >
        {timeOff && timeOffBadge}
      </Avatar>
    )
  }

  return (
    <>
      <Relative
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <Avatar
          size={56}
          image={getAvatarUrl(data.avatar, 'md')}
          label={abbreviate(data.full_name)}
          {...tooltip.getAnchorProps()}
          {...(timeOff && {
            progress: 1,
            progressColor: Token.color.warning,
          })}
        >
          {timeOff && timeOffBadge}
        </Avatar>
        {isMouseOver ? (
          <Circle
            bg="modal-overlay"
            size={38}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              cursor: 'pointer',
            }}
            onClick={() => setAvatarUploadOpen(true)}
          >
            <Icon name="Camera" color="white" size={24} />
          </Circle>
        ) : null}
      </Relative>
      <UserAvatarUpload
        open={avatarUploadOpen}
        onClose={() => setAvatarUploadOpen(false)}
        onSuccess={refreshData}
      />
    </>
  )
}
